import { Component } from '@angular/core';
/**
	 * @ignore
	 */
@Component({
	selector: 'app-blank-layout',
	templateUrl: './blank.component.html',
	styleUrls: []
})
export class BlankComponent {}
